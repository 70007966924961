<template>
	<div class="DeviceEvent2OpCfgList">
		
		<p v-if="funDef.id" class="title">控制配置</p>
		<div>
			<el-button size="mini" type="primary" @click="addCmd()">新增</el-button>
			<el-button size="mini" type="primary" @click="closeSelfDrawer()">关闭</el-button>
			<el-button size="mini" type="primary" @click="refresh()">刷新</el-button>
		</div>
		
		<table v-if="plist && plist.length > 0" class="configItemTalbe" width="99%">
			<thead>
				<tr>
					<td>{{"事件设备"}}</td>
					<td>{{"事件名称"}}</td>
					<td>{{"事件类型"}}</td>
					<td>{{"操作设备"}}</td>
					<td v-if="cmd">操作名称</td><td v-else>{{'OP ID'|i18n}}</td>
					
					<!-- <td>{{'exeBy'|i18n}}</td> -->
					<td>{{'ExeName'|i18n}}</td>
					<!-- <td>{{'responsorId'|i18n}}</td> -->
					<td>{{'主题'}}</td>
					
					<td>{{'desc'|i18n}}</td>
					<td>{{'status'|i18n}}</td>
					<!-- <td>{{'clientId'|i18n}}</td>
					<td>{{'createdBy'|i18n}}</td> -->
					
					<td>{{"Operation"|i18n}}</td>
				</tr>
			</thead>
			<tr v-for="c in plist" :key="'h_'+c.id">
				<td>{{allDeviceMap[c.eventDeviceId]}}</td>
				<td>{{c.eventName}}</td>
				<td>{{getTypeLabel(c.eventCode)}}</td>
				<td>{{allDeviceMap[c.opDeviceId]}}</td>
				<td>{{c.opName}}</td>
				<!-- <td>{{c.exeBy}}</td> -->
				<td>{{$jm.IOT.JM_EVENT_EXE_LABELS[c.exeBy-1]}}</td>
				<!-- <td>{{c.responsorId}}</td> -->
				<td>{{c.exeBy!=$jm.IOT.JM_EVENT_EXE.LOCAL_DEV?topicsMap[c.responsorId+""]:"无"}}</td>
				<td>{{c.desc}}</td>
				<td>{{c.status==1?"启用":"禁用"}}</td>
				<!-- <td>{{c.clientId}}</td>
				<td>{{c.createdBy}}</td> -->
				<td>
					<a @click="viewDetail(c)">{{'View'|i18n}}&nbsp;</a>
					<a v-if="$jr.auth.updateAuth(c.createdBy)" @click="updateCmd(c)">{{'Update'|i18n}}&nbsp;</a>
					<a v-if="$jr.auth.updateAuth(c.createdBy)" @click="deleteAs(c)">{{'Delete'|i18n}}&nbsp;</a>
				</td>
			</tr>
		</table>

		<div v-if="isLogin && plist && plist.length > 0" style="position:relative;text-align:center;">
			<Page ref="pager" :total="totalNum" :page-size="queryParams.size" :current="queryParams.curPage"
				show-elevator show-sizer show-total @on-change="curPageChange" @on-page-size-change="pageSizeChange"
				:page-size-opts="[10, 30, 60,100]"></Page>
		</div>

		<div v-if="!isLogin">
			No permission!
		</div>

		<div v-if="!plist || plist.length == 0">
			No data!
		</div>
		
		<Drawer ref="asInfo"  v-model="asDrawer.drawerStatus" :closable="false" placement="right" :transfer="false"
		        :styles="asDrawer.drawerBtnStyle" :draggable="true" :scrollable="true" width="50" :mask-closable="false" :mask="false">
			
			<el-row style="margin-bottom: 5px;">
				<el-button  :disabled="model==3" size="mini" type="primary" @click="doAddOrUpdateParam">保存</el-button>
				<el-button size="mini" type="primary" @click="asDrawer.drawerStatus=false">关闭</el-button>
			</el-row>
			
			<el-row>
				<el-col>触发事件&nbsp;
					<el-button :disabled="model==3"  size="mini" type="primary" @click="selectEvent()">先择事件</el-button>
				</el-col>
				<el-col v-if="selEvent && selEvent.id">
					<el-input v-if="selEvent.eventCode==$jm.IOT.EVENT_CODES.ASR" v-model="selEvent.sext1" :disabled="true" />
					<el-input v-else v-model="selEvent.desc" :disabled="true" />
				</el-col>
				<el-col v-else>
					<el-input :disabled="true" v-model="as.eventName" />
				</el-col>
			</el-row>
			
			<el-row v-if="as.eventBy == $jm.IOT.JM_OP_SRC.DEVICE">
				<el-col>触发设备</el-col>
				<el-col>
					<el-input :disabled="true" :value="allDeviceMap[as.eventDeviceId]"/>
				</el-col>
			</el-row>
			<el-row v-else>
				<el-col>触发设备</el-col>
				<el-col>
					<el-select style="width:100%" v-model="as.eventDeviceId" :disabled="model==3">
						<el-option v-for="(v,k) in allDeviceMap" :key="k" :value="k" :label="v"></el-option>
					</el-select>
				</el-col>
			</el-row>
			
			<el-row>
				<el-col>操作
					<el-button v-if="by==0 || model==3" type="primary" size="mini" @click="selectOp()">先择操作</el-button>
				</el-col>
				<el-col>
					<el-input v-model="as.opName" :disabled="true"/>
				</el-col>
			</el-row>
			
			<el-row>
				<el-col>操作目标设备</el-col>
				<el-col v-if="dev.id || model==3">
					<el-input  v-model="allDeviceMap[as.opDeviceId]" :disabled="true" />
				</el-col>
				<el-col v-else>
					<el-select style="width:100%" v-model="as.opDeviceId" :disabled="model==3">
						<el-option v-for="(v,k) in allDeviceMap" :key="k" :value="k" :label="v"></el-option>
					</el-select>
				</el-col>
			</el-row>
		
			<el-row>
				<el-col>执行方</el-col>
				<el-col>
					<el-select style="width:100%" v-model="as.exeBy" :disabled="model==3">
						<el-option v-for="(v,k) in $jm.IOT.JM_EVENT_EXE" :key="v" :value="v" 
						:label="$jm.IOT.JM_EVENT_EXE_LABELS[v-1]"></el-option>
					</el-select>
				</el-col>
			</el-row>

			<el-row  v-if="as.exeBy==$jm.IOT.JM_EVENT_EXE.SERVER || as.exeBy==$jm.IOT.JM_EVENT_EXE.THIRD">
				<el-col>{{exeNameLabel+"(完整主题为 /_a/m/"+(this.$jr.auth.actInfo.id)+"/"+(as.responsorId?topicsMap[as.responsorId]:"")+")" }}</el-col>
				<el-col>
					<el-select style="width:100%" v-model="as.responsorId" :disabled="model==3">
						<el-option v-for="(v,k) in topicsMap" :key="k" :value="k" :label="v"></el-option>
					</el-select>
				</el-col>
			</el-row>
			
			 <el-row>
				<el-col>启用</el-col>
				<el-select style="width:100%" v-model="as.status" :disabled="model==3" placeholder="请选择">
					<el-option :key="1" :value="1" label="启用"></el-option>
					<el-option :key="0" :value="0" label="禁用"></el-option>
				</el-select>
			 </el-row>
			 
			 <el-row>
			 	<el-col>描述</el-col>
			 	<el-col><el-input v-model="as.desc" :disabled="model==3" /></el-col>
			 </el-row>
			 
			 <el-row v-if="model==3 || model==1">
			 	<el-col>ID</el-col>
			 	<el-col><el-input v-model="as.id" :disabled="true" /></el-col>
			 </el-row>
			 
			 <el-row v-if="model==3 || model==1">
			 	<el-col>操作ID</el-col>
			 	<el-col><el-input v-model="as.opId" :disabled="true" /></el-col>
			 </el-row>
			 
			 <el-row v-if="model==3 || model==1">
			 	<el-col>事件ID</el-col>
			 	<el-col><el-input v-model="as.eventId" :disabled="true" /></el-col>
			 </el-row>
			
		</Drawer>
		
		<Drawer ref="asInfo" v-model="eventSelDrawer.drawerStatus" :closable="false" placement="right"
		 	:transfer="false"
		 	:styles="eventSelDrawer.drawerBtnStyle" :draggable="true" :scrollable="true" 
		 	width="50" :mask-closable="false" :mask="false" :z-index="99999"
		 	style="z-index: 33333;">
		 	<DeviceEventCfgList ref="eventCfgList" :selMode="true" @onClose="onCloseSelectEvent"  @onSeletEvent="onSeletEvent"></DeviceEventCfgList>
		</Drawer>
		
		<Drawer ref="asInfo" v-model="opSelDrawer.drawerStatus" :closable="false" placement="right":transfer="false"
		 	:styles="opSelDrawer.drawerBtnStyle" :draggable="true" :scrollable="true" 
		 	width="50" :mask-closable="false" :mask="false" :z-index="99999"
		 	style="z-index: 33333;">
		 	<DeviceFunCmdList :selMode="true" @onClose="onOpCloseEvent"  @onSeletEvent="onOpSelectEvent"></DeviceFunCmdList>
		</Drawer>
		
		<!-- 弹出查询页面 -->
		<div v-if="isLogin"  :style="queryDrawer.drawerBtnStyle" class="drawerJinvokeBtnStatu" @mouseenter="openQueryDrawer()"></div>
		<!-- 数据查询开始-->
		<Drawer v-if="isLogin"   v-model="queryDrawer.drawerStatus" :closable="false" placement="left" :transfer="true"
		         :draggable="true" :scrollable="true" width="25">
				 
			 <el-row>
				<el-col>事件设备</el-col>
				<el-col>
					<el-select style="width:100%" v-model="queryParams.ps.eventDeviceId">
						<el-option  :key="-1" :value="-1" label="全部"></el-option>
						<el-option v-for="(v,k) in allDeviceMap" :key="k" :value="k" :label="v"></el-option>
					</el-select>
				</el-col>
			 </el-row>
			 
			 <el-row>
				<el-col>操作设备</el-col>
				<el-col>
					<el-select style="width:100%" v-model="queryParams.ps.opDeviceId">
						<el-option  :key="-1" :value="-1" label="全部"></el-option>
						<el-option v-for="(v,k) in allDeviceMap" :key="k" :value="k" :label="v"></el-option>
					</el-select>
				</el-col>
			 </el-row>
			  
			 <el-row>
				<el-col>启用</el-col>
				<el-select style="width:100%" v-model="queryParams.ps.status" placeholder="请选择">
					<el-option  :key="-1" :value="-1" label="全部"></el-option>
					<el-option :key="1" :value="1" label="启用"></el-option>
					<el-option :key="0" :value="0" label="禁用"></el-option>
				</el-select>
			 </el-row>
			 
			 <el-row>
				<el-button size="mini" @click="queryDrawer.drawerStatus = false">取消</el-button>
				<el-button size="mini" type="primary" @click="doQuery">{{"Query"|i18n}}</el-button>
			 </el-row>
				 
		</Drawer><!-- 数据查询结束-->
		
	</div>
</template>

<script>
	const cid = 'DeviceEvent2OpCfgList';
	import jmiot from "../../rpcservice/jm.js"
	import DeviceEventCfgList from "./DeviceEventCfgList.vue"
	import DeviceFunCmdList from "./DeviceFunCmdList.vue"
	
	export default {
		name: cid,
		components: {DeviceEventCfgList, DeviceFunCmdList},
		props: {
			
		},
		data() {
			
			return {
				funDef:{},
				cmd:{},
				dev:{},
				by:0,
				
				eventCodeTypes:[],
				
				as: {eventBy:this.$jm.IOT.JM_OP_SRC.DEVICE},
				model: 3,

				errorMsg: '',
				isLogin: true,
				plist: [],

				masterDeviceMap: {},
				slaveDeviceMap: {},
				allDeviceMap: {},
				topicsMap: {},
				
				selEvent:{},
				exeNameLabel:"",

				queryParams: {
					size: 30,
					curPage: 1,
					ps: {
						selectType: 0
					}
				},
				totalNum: 0,

				asDrawer: {
					drawerStatus: false,
					drawerBtnStyle: {
						left: '0px',
						zindex: 9999
					},
				},
				
				eventSelDrawer:{
					drawerStatus: false,
					drawerBtnStyle: {
						left: '0px',
						zindex: 9999
					},
				},
				
				opSelDrawer:{
					drawerStatus: false,
					drawerBtnStyle: {
						left: '0px',
						zindex: 9999
					},
				},
				
				queryDrawer: {
				    drawerStatus:false,
				    drawerBtnStyle:{left:'0px',zindex:1000},
				},
			}
		},

		watch:{
            "as.exeBy":function(exeBy){
				this.updateExeLabel(exeBy)
            },
			
			"as.eventBy":function(eventBy){
				console.log("as.eventBy"+eventBy)
			}
        },
		
		/*
		computed:{
			canSameAsEvent() {
				return 
			}
		},
		*/
	   
		methods: {
			
			openQueryDrawer() {
			    this.queryDrawer.drawerStatus = true
			    this.queryDrawer.drawerBtnStyle.zindex = 10000
			    this.queryDrawer.drawerBtnStyle.left = '0px'
			},
			
			init(funDef, cmd, dev, by){
				this.def = funDef
				this.cmd = cmd
				this.dev = dev
				this.by = by
				
				this.exeBy = this.$jm.IOT.JM_EVENT_EXE.SERVER
				
				this.as.opId = cmd.id
				this.as.opName = cmd.name
				if(this.dev) {
					this.as.opDeviceId = this.dev.deviceId
				}
				this.updateExeLabel(this.exeBy)
				this.refresh()
			},
			
			updateExeLabel(exeBy) {
				this.exeNameLabel = this.$jm.IOT.JM_EVENT_EXE_LABELS[exeBy-1]
				//this.as.responsorId = ""
				if(exeBy == this.$jm.IOT.JM_EVENT_EXE.SERVER) {
					this.exeNameLabel +="主题"
				}else if(exeBy == this.$jm.IOT.JM_EVENT_EXE.LOCAL_DEV) {
					//this.loadMasterDevice(this.$jm.IOT.DEV_ROLE.MASTER, this.masterDeviceMap)
					this.masterDeviceMap = this.masterDeviceMap
					//this.exeNameLabel +="ID"
				}else if(exeBy == this.$jm.IOT.JM_EVENT_EXE.THIRD) {
					this.exeNameLabel +="主题"
				}
			},
			
			getTypeLabel(ec) {
				let ecv = this.eventCodeTypes.find(e=>parseInt(e.attr0.toString())==ec);
				if(ecv && ecv.name.length > 0) {
					return ecv.name
				}else {
					return this.$jm.IOT.getEventCodeLabel(ec)
				}
			},
			
			selectOp(){
				this.opSelDrawer.drawerStatus = true;
			},
			
			onOpCloseEvent() {
				this.opSelDrawer.drawerStatus = false
			},
			
			onOpSelectEvent(op){
				this.opSelDrawer.drawerStatus = false
				console.log("op",op)
				this.as.opId = op.id
				this.as.opName = op.name
				//this.as.opDeviceId
			},
			
			onCloseSelectEvent() {
				this.eventSelDrawer.drawerStatus = false
			},
			
			onSeletEvent(event){
				this.eventSelDrawer.drawerStatus = false
				console.log("event:",event)
				if(!event.id) {
					return
				}
				
				this.selEvent = Object.assign(this.selEvent, event)
				this.as.eventDeviceId = this.selEvent.srcDeviceId
				this.as.eventName = this.selEvent.eventCode==this.$jm.IOT.EVENT_CODES.ASR ?
					this.selEvent.sext1 : this.selEvent.desc
				
				this.as.eventBy = this.selEvent.by
			
				console.log("this.allDeviceMap", this.allDeviceMap)
				
			},
			
			selectEvent(){
				//this.$refs.eventCfgList.addEvent(this.funDef, this.cmd, this.by, this.dev)
				this.eventSelDrawer.drawerStatus = true;
			},
			
			closeSelfDrawer(){
				this.$emit("onClose");
			},
			
			closeDrawer() {
				this.$parent.$parent.closeAsDrawer()
			},

			resetData() {
				this.as = {status:true, clientId:this.$jr.auth.actInfo.clientId}
			},

			viewDetail(as) {
				this.model = 3
				this.as = as
				this.as.responsorId += ""
				
				this.updateExeLabel(as.exeBy)
				this.asDrawer.drawerBtnStyle.zindex = 99
				this.asDrawer.drawerStatus = true;
			},

			addCmd() {
				this.as = { eventBy:this.$jm.IOT.JM_OP_SRC.DEVICE}
				this.model = 2;
				this.resetData()
				this.asDrawer.drawerBtnStyle.zindex = 99
				this.asDrawer.drawerStatus = true;
				this.$forceUpdate()
			},

			updateCmd(as) {
				this.model = 1;
				this.as = as
				this.as.responsorId += ""
				
				this.updateExeLabel(as.exeBy)

				this.asDrawer.drawerBtnStyle.zindex = 99
				this.asDrawer.drawerStatus = true;
			},

			async deleteAs(c) {
				
				this.$confirm('此操作将永久删除当前数据，是否继续?', '提示', {
				  confirmButtonText: '确定',
				  cancelButtonText: '取消',
				  type: 'warning'
				}).then(async () => {
					let r = await this.$jr.rpc.invokeByCode(517878475, [c.id])
					if (r.code == 0) {
						let idx = this.plist.findIndex(e => e.id == c.id)
						if (idx >= 0) {
							this.plist.splice(idx, 1)
						}
						this.$notify.info({
							title: '提示',
							message: "删除成功"
						})
					} else {
						this.$notify.error({
							title: '提示',
							message: r.msg || "删除失败"
						})
					}
				}).catch(() => {
				});
			},

			doQuery() {
				this.queryParams.curPage = 1
				this.refresh();
			},

			doAddOrUpdateParam() {
				if(this.selEvent.id) {
					this.as.eventId = this.selEvent.id
				}else if(!this.as.eventId){
					this.$notify.error({
						title: '提示',
						message: "需选择一个触发此事件"
					})
					return
				}
				
				if(!this.as.opDeviceId){
					this.$notify.error({
						title: '提示',
						message: "需选择操作目标设备"
					})
					return
				}
				
				if(!this.as.eventDeviceId){
					this.$notify.error({
						title: '提示',
						message: "需选择触发事件设备"
					})
					return
				}
				
				//console.log("cmd ", this.cmd)
				if(!this.as.opId) {
					this.$notify.error({
						title: '提示',
						message: "需选择一个目标动作"
					})
					return
				}
				
				//this.as.opId = this.cmd.id
				
				//console.log("Desc", this.as.desc)
				if(!this.as.desc || this.as.desc.trim().length == 0) {
					this.$notify.error({
						title: '提示',
						message: "请描述一下事件操作"
					});
					return
				}
				
				if (!this.as.exeBy) {
					this.$notify.error({
						title: '提示',
						message: "需选择一个执行类型"
					})
					return
				}
				
				if(this.as.exeBy == this.$jm.IOT.JM_EVENT_EXE.SERVER ||
					this.as.exeBy == this.$jm.IOT.JM_EVENT_EXE.THIRD) {
						if(this.model == 2) {//add
							if(!this.as.responsorId || this.as.responsorId.trim().length == 0) {
								this.$notify.error({
									title: '提示',
									message: "需输入一个有效的分发主题"
								})
								return
							}
						}
						this.as.responsorId = parseInt(this.as.responsorId)
				}else{
					this.as.responsorId = 0
				}
				
				console.log("AS", this.as)
				if (this.model == 1) {
					//update
					this.$jr.rpc.invokeByCode(1987607979, [this.as])
					.then((resp) => {
						this.as.responsorId += ""
						this.refresh()
						if (resp.code == 0) {
							this.$notify.info({
								title: '提示',
								message: "更新成功"
							})
							this.asDrawer.drawerStatus = false;
						} else {
							this.$notify.error({
								title: '错误',
								message: resp.msg || "未知错误"
							})
						}
					}).catch((err) => {
						this.$notify.error({
							title: '错误',
							message: JOSN.stringify(err)
						})
					})
				} else if (this.model == 2) {
					//add
					this.$jr.rpc.invokeByCode(-177970775, [this.as])
					.then((resp) => {
						this.as.responsorId += ""
						if (resp.code == 0) {
							this.$notify.info({
								title: '提示',
								message: "保存成功"
							})
							this.asDrawer.drawerStatus = false
							this.refresh()
						} else {
							this.$notify.error({
								title: '错误',
								message: resp.msg || "未知错误"
							})
						}
					}).catch((err) => {
						this.$notify.error({
							title: '错误',
							message: JOSN.stringify(err)
						})
					})
				}

			},

			curPageChange(curPage) {
				this.queryParams.curPage = curPage
				this.refresh()
			},

			pageSizeChange(pageSize) {
				this.queryParams.size = pageSize
				this.queryParams.curPage = 1
				this.refresh()
			},
			
			async loadTopics() {
				let loaded = false
				for(let k in this.topicsMap) {
					loaded = true
					break;
				}
				
				if(loaded) {
					return
				}
				
				let r = await this.$jr.rpc.invokeByCode(-1223587480, [])
				console.log("dev list", JSON.stringify(r))
				if(r.code != 0) {
					this.$notify.error({title: '提示',message: r.msg || '加载主题数据错误'})
					return
				}
				
				if(!r.data || r.data.length == 0) {
					//map["0"] = "" //防止重复加载无效请求
					console.log("无主题数据")
					return
				}
				
				this.topicsMap = r.data
			},
			
			async loadMasterDevice(devType, map){
				
				let loaded = false
				for(let k in map) {
					loaded = true
					break;
				}
				
				if(loaded) {
					return
				}
				
				let r = await this.$jr.rpc.invokeByCode(-2138649681, [devType])
				console.log("dev list", JSON.stringify(r),devType)
				if(r.code != 0) {
					this.$notify.error({title: '提示',message: r.msg || '加载主设备数据错误'})
					return
				}
				
				if(!r.data || r.data.length == 0) {
					map["0"] = "" //防止重复加载无效请求
					console.log("无主设备数据")
					return
				}
				if(devType == this.$jm.IOT.DEV_ROLE.ALL) {
					for(let k in r.data) {
						//console.log(k + "=" + r.data[k])
						map[k] =  r.data[k]
						if(r.data[k].endWith("\\(主控\\)")) {
							this.masterDeviceMap[k] =  r.data[k]
						}else{
							this.slaveDeviceMap[k] =  r.data[k]
						}
					}
				} else {
					for(let k in r.data) {
						map[k] =  r.data[k]
					}
				}

				console.log("$forceUpdate")
				this.$forceUpdate()
			},

			async refresh() {
				let self = this

				this.isLogin = this.$jr.auth.isLogin()
				if (this.isLogin) {

					await this.loadMasterDevice(this.$jm.IOT.DEV_ROLE.ALL, this.allDeviceMap)
					await this.loadTopics()
					
					let params = this.getQueryConditions()
					let self = this

					//listActiveSources
					this.$jr.rpc.invokeByCode(161442542, [params])
						.then((resp) => {
							if (resp.code == 0) {
								if (resp.total == 0) {
									this.plist = []
									this.totalNum = 0
									this.$notify.info({
										title: '提示',
										message: "查无数据"
									})
								} else {
									console.log(resp)
									this.plist = resp.data
									this.totalNum = resp.total
									if (this.totalNum) {
										this.plist.forEach(e => {
											e.selected = !!e.productId
										})
									}
								}
							} else {
								this.plist = []
								this.totalNum = 0
								this.$notify.error({
									title: '提示',
									message: r.msg
								})
							}
						}).catch((err) => {
							window.console.log(err)
						});
				} else {
					self.roleList = []
				}
			},

			getQueryConditions() {
				if(this.cmd && this.cmd.id) {
					this.queryParams.ps.opId = this.cmd.id
				}
				
				if(this.dev && this.dev.deviceId) {
					this.queryParams.ps.opDeviceId = this.dev.deviceId
				}
				
				for(let k in this.queryParams.ps) {
					if(this.queryParams.ps[k]=='-1' || this.queryParams.ps[k]==-1) {
						this.queryParams.ps[k] = null;
					}
				}

				return this.queryParams
			}
			
	},

	async mounted() {
			//this.$el.style.minHeight = (document.body.clientHeight - 67) + 'px';
			//this.$jr.auth.addActListener(this.refresh);
			let r = await this.$jr.api.getDataType("iot_eventCode")
			if(r.code == 0) {
				this.eventCodeTypes = r.data
			}
			let self = this;
			this.refresh();
			
			this.$bus.$emit("editorOpen", {
				"editorId": cid,
				"menus": [{
						name: "REFRESH",
						label: "Refresh Event",
						icon: "ios-cog",
						call: self.refresh
					},
					{
						name: "Add",
						label: "Add Event",
						icon: "ios-cog",
						call: self.addCmd
					},
				]
			});

			let ec = function() {
				this.$jr.auth.removeActListener(cid);
				this.$off('editorClosed', ec);
			}

			this.$bus.$on('editorClosed', ec);
		},

		beforeDestroy() {
			this.$jr.auth.removeActListener(cid);
		},

	}
</script>

<style>
	.DeviceEvent2OpCfgList {
		border-top: 1px dotted lightgray;
		margin-top: 6px;
		padding-top: 10px;
		text-align: left;
	}

	.title {
		font-weight: bold;
		font-size: 17px;
		text-align: center;
	}

	.valCol {
		overflow: hidden;
		text-overflow: ellipsis;
		flex-wrap: nowrap;
	}
</style>